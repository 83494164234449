import React from "react";
import Intent from "./link";

export default function Appbar() {
  return (
    <div className="container my-3">
      <ul className="nav justify-content-center">
        <li className="nav-item mx-4">
          <Intent to="/"> Home </Intent>{" "}
        </li>{" "}
        <li className="nav-item mx-4">
          <a
            class="nav-link"
            activeStyle={{
              color: "white"
            }}
            style={{
              color: "grey"
            }}
            href="https://pensieve.vidhyaranganathan.com"
          >
            Pensieve{" "}
          </a>{" "}
        </li>{" "}
         <li className="nav-item mx-4">
          <a
            class="nav-link"
            activeStyle={{
              color: "white"
            }}
            style={{
              color: "grey"
            }}
            href="https://open.spotify.com/show/4y3zpr4QQCFdcbJbcG2NDx?si=724414ead255421d"
          >
            Engineering Productivity Podcast{" "}
          </a>{" "}
        </li>{" "}
      </ul>{" "}
    </div>
  );
}
