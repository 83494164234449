import React from "react";

import {
  FaTwitter as Twitter,
  FaGithub as Github,
  FaEnvelope as Mail
} from "react-icons/fa";

export default function info() {
  return (
    <div className="container">
      <div className="my-5"> </div>{" "}
      <div className="display-3" style={{ color: "#FF8A00" }}>
        <span style={{ color: "white" }}> Hello, I 'm</span> <br />
        Vidhya.{" "}
      </div>{" "}
      <div className="h1 code mt-4 mb-3"> more {"{"} </div>{" "}
      <div className="text-muted mx-5 my-4 h3 text-justify info">
        Quality Advocate | Mom <br /> 👩 Person with a kaleidoscope of interests.{" "}
        <br /> ✍️ (Not very actively)Writing my personal thoughts in Pensieve.{" "}
        <br /> 🎙️ Hosting engineering productivity podcast where I speak about SDLC, quality, community and careers.{" "}
        <br /> 🎹 Devout Music listener. Thillanas are my current obsession.  {" "}
        <br /> 🎻 Aspiring violinist. {" "}
        <br /> ✨ Yes, I love Harry Potter!{" "}
      </div>{" "}
      <div className="h1 code mt-2 mb-3"> {"}"} </div>{" "}
      <div className="h1 mt-5">
        <a className="mr-5 icon" href="https://twitter.com/vidhyarang">
          <Twitter />
        </a>{" "}
        <a className="mr-5 icon" href="https://github.com/vidhya0406">
          <Github />
        </a>{" "}
        <a className="mr-5 icon" href="mailto://vidhya0406@gmail.com">
          <Mail />
        </a>{" "}
      </div>{" "}
    </div>
  );
}
